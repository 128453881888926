




































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModuleService } from '@/plugins/podocore';
// Import utils ------------------------------------------------------------------------------------
import { useCDN } from '@/utils/cdn.utils';
import { useAnalytics } from '@/utils/analytics.utils';
import { useRoute } from '@/utils/router.utils';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'LayoutAuthContent',
  setup(_, { root }) {
    const { cdn } = useCDN();
    const { campaignQuery } = useRoute();
    const { trackSuccess, campaignQueryParametersToEvent } = useAnalytics();

    const authService = usePodocoreModuleService('auth');

    const signInChild = authService.service.value.children.get('signIn');
    const signUpChild = authService.service.value.children.get('signUp');

    const signInService = signInChild ? useService(signInChild as any) : undefined;
    const signUpService = signUpChild ? useService(signUpChild as any) : undefined;

    async function navigateToSignUp() {
      // Analytics
      trackSuccess('User SignupClicked', campaignQueryParametersToEvent(campaignQuery));
      // Navigate to...
      await root.$router.push({
        name: 'auth--sign-up',
        // Keep campaign parameters in query string
        query: campaignQuery.value
      });
    }

    async function navigateToSignIn() {
      // Analytics
      trackSuccess('User SigninClicked', campaignQueryParametersToEvent(campaignQuery));
      // Navigate to...
      await root.$router.push({
        name: 'auth--sign-in',
        // Keep campaign parameters in query string
        query: campaignQuery.value
      });
    }

    function signOut() {
      authService?.send({ type: 'SIGN_OUT' });
    }

    const isWaitingCode = computed(() => {
      if (signInService) return signInService?.state.value.matches({ confirming: 'idle' });
      if (signUpService) return signUpService?.state.value.matches({ confirming: 'idle' });
      return false;
    });

    return {
      cdn,
      navigateToSignUp,
      navigateToSignIn,
      signOut,
      isWaitingCode
    };
  }
});
