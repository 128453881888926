



















// Import vendors ----------------------------------------------------------------------------------
import { computed } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { Layout, layoutFactory } from '@/utils/layouts.utils';
import { useRoute } from '@/utils/router.utils';
// Import components -------------------------------------------------------------------------------
import LayoutAuthSidebar from '@/layouts/auth/components/sidebar/LayoutAuthSidebar.vue';
import LayoutAuthContent from '@/layouts/auth/components/sidebar/LayoutAuthContent.vue';
// -------------------------------------------------------------------------------------------------

export default layoutFactory(
  {
    layout: Layout.Auth
  },
  {
    components: {
      LayoutAuthSidebar,
      LayoutAuthContent
    },
    setup(_, { root }) {
      const route = useRoute();

      const sidebarIsVisible = computed(() => {
        return root.$vuetify.breakpoint.lgAndUp;
      });

      const footerIsVisible = computed(() => {
        return route.name.value === 'auth--sign-up';
      });

      return {
        sidebarIsVisible,
        footerIsVisible
      };
    }
  }
);
